<template>
  <div class="d-flex flex-column">
    <v-img
      src="https://images.unsplash.com/photo-1547570135-c4d6810a9ada?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
      alt=""
      max-height="300"
      height="300"
    ></v-img>
    <v-container fluid>
      <v-card flat color="transparent">
        <v-card-title class="text-h5">Telefon Doğrula</v-card-title>
        <v-card-subtitle>Cep telefonunuza gönderilen 6 haneli doğrulama kodunu giriniz.</v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-otp-input length="6" v-model="approveForm.smsCode" type="number" ref="smsCode"></v-otp-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="text-capitalize rounded-lg"
            color="primary"
            block
            height="45"
            @click="validateSmsCode"
            v-if="smsResponse"
          >
            Doğrula <span class="ml-2" v-if="smsResponse"> ({{ smsResponse.timeOutSecond }})</span>
          </v-btn>
          <v-btn class="text-capitalize rounded-lg" color="primary" block height="45" @click="resendSmsCode" v-else>
            Yeni kod al
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { APPROVE, RESEND_CODE, DECREASE_SECOND } from '@/store/modules/auth.module';

export default {
  name: 'Index',
  components: {},
  data() {
    return {
      approveForm: {
        smsCode: null,
      },
    };
  },
  computed: {
    ...mapGetters(['smsResponse', 'mobilePhone']),
  },
  created() {
    if (this.smsResponse !== null) {
      this.$nextTick(() => {
        const interval = setInterval(() => {
          this.decreaseSecond();
          if (this.smsResponse === null) {
            clearInterval(interval);
          }
        }, 1000);
      });
    }
  },
  methods: {
    ...mapActions({
      approveSmsCode: APPROVE,
      resendCode: RESEND_CODE,
    }),
    ...mapMutations({
      decreaseSecond: DECREASE_SECOND,
    }),
    validateSmsCode() {
      const payload = {
        key: this.smsResponse.key,
        smsCode: this.approveForm.smsCode,
      };
      this.approveSmsCode(payload).then(() => {
        this.$router.replace({ name: 'garage' });
      });
    },
    resendSmsCode() {
      const payload = this.mobilePhone;
      this.resendCode(payload).then(() => {});
    },
  },
};
</script>

<style></style>
